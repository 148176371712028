import React, { useEffect, useRef } from "react";
import useDefaultValue from "../../hook/useDefaultValue";
import useErrorBorder from "../../hook/useErrorBorder";
import style from "./index.module.less";

export const BaseInput = ({
  value = "",
  defaultValue = "",
  meta,
  ...props
}) => {
  useDefaultValue({ defaultValue, onChange: props.onChange });
  const s = useErrorBorder(meta);

  return (
    <input className={style.input} value={value} style={{ ...s }} {...props} />
  );
};

export default function Input({
  value = "",
  label,
  lineHeight = "2.3611vw",
  meta,
  ...props
}) {
  const boxRef = useRef(null)
  useEffect(() => {
    if (props.defaultValue && typeof props.onChange === "function") {
      props.onChange(props.defaultValue);
    }
  }, [props.defaultValue]);

  function handelInputFocus() {
    boxRef.current.lastElementChild.focus()
  }

  return (
    <div className="icy-form-field" ref={boxRef}>
      {label && (
        <span
          style={{
            lineHeight,
            borderColor: meta?.errors?.length ? "#E02020" : "#ccc",
          }}
          className={`icy-form-field__label--underline`}
          onClick={handelInputFocus}
        >
          {label}
        </span>
      )}

      <BaseInput value={value} meta={meta} {...props} />
    </div>
  );
}
