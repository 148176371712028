import React, { useRef } from "react";
import { Field } from "rc-field-form";
import { genErrorBorderColor } from "../../hook/useErrorBorder";
import style from "./index.module.less";

export default function ErrorField({
  name,
  children,
  label,
  lineHeight = "32px",
  labelUnderline,
  labelNewline,
  labelColor = "#000",
  ...props
}) {

  const boxRef = useRef(null)

  function handelInputFocus() {
    boxRef.current.querySelector('input').focus();
  }

  return (
    <Field name={name} {...props}>
      {(control, meta, form) => {
        const childNode =
          typeof children === "function"
            ? children(control, meta, form)
            : React.cloneElement(children, { ...control, meta });

        return (
          <section className={style.container}>
            {label && labelNewline && (
              <div style={{ color: labelColor }} className={style.label} onClick={handelInputFocus}>
                {label}
              </div>
            )}
            <div className="icy-form-field">
              {label && !labelNewline && (
                <span
                  style={{ lineHeight, borderColor: genErrorBorderColor(meta) }}
                  className={`icy-form-field__label${
                    labelUnderline ? "--underline" : ""
                  }`}
                  onClick={handelInputFocus}
                >
                  {label}
                </span>
              )}
              <div className="icy-form-field__content" ref={boxRef}>{childNode}</div>
            </div>
            <div className={style.error}>{meta.errors}</div>
          </section>
        );
      }}
    </Field>
  );
}
