import React, { useState } from "react";
import Form from "rc-field-form";
import md5 from "md5";
import Message from "rc-message";
import Cookies from "js-cookie";
import axios from "../../../utils/request";
import ErrorField from "../../../components/error-field";
import Input from "../../../components/input";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import style from "./login.module.css";

export default ({ location }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={style.registerWrap}>
      <Cursor></Cursor>
      <PublisherHeader pathName={location.pathname}></PublisherHeader>
      <div className={style.applyStep}>
        <h2>登录</h2>
        <Form
          className={style.accountWrap}
          autoComplete="false"
          onFinish={async (values) => {
            values.password = md5(values.password.trim()).toString();
            setLoading(true);
            const { data } = await axios({
              method: "post",
              headers: { "Content-Type": "application/json;charset=UTF-8" },
              url: "/designer/login",
              data: values,
            });
            setLoading(false);
            if (data?.success) {
              Cookies.set("token", data.token, { expires: 1 });
              Cookies.set("userId", data.userId, { expires: 1 });
              const { data: userData } = await axios({
                method: "get",
                headers: { "Content-Type": "application/json;charset=UTF-8" },
                url: "/designer/userinfo",
              });
              if (
                userData.status === 0 ||
                (userData.status === 1 && userData.contractStatus === 9) ||
                userData.status === 2
              ) {
                window.open("/designerPress/verify", "_blank");
                // navigateTo("/designerPress/verify");
              }
              if (userData.contractStatus === 0) {
                window.open("/designerPress/contract", "_blank");
                // navigateTo("/designerPress/contract");
              }
              if (
                userData.contractStatus === 1 && userData.jointInfoStatus === 0
              ) {
                window.open("/designerPress/jointInfo", "_blank");
                // navigateTo("/designerPress/jointInfo");
              }
              if (
                userData.contractStatus === 1 &&
                userData.profileStatus === 0 && userData.jointInfoStatus === 1
              ) {
                window.open("/designerPress/fullInfo", "_blank");
                // navigateTo("/designerPress/fullInfo");
              }
              if (userData.profileStatus === 1) {
                window.open("/designerPress/designerIndex", "_blank");
                // navigateTo("/designerPress/designerIndex");
              }
            } else {
              Message.warning({
                content: data.msg,
              });
            }
          }}
        >
          <div className={style.fillWrap}>
            <ErrorField
              name="account"
              rules={[
                {
                  required: true,
                  message: "请输入手机号",
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input placeholder="请输入手机号" autoComplete="false" />
            </ErrorField>
          </div>

          <div className={style.fillWrap}>
            <ErrorField
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input
                placeholder="密码"
                autoComplete="new-password"
                type="password"
              />
            </ErrorField>
          </div>
          <button
            className={`${style.buttonStyle} ${loading ? style.grey : ""}`}
            disabled={loading}
          >
            {loading && (
              <img
                width="640"
                height="360"
                src={require("../../../img/icon_loading.png")}
                alt="icy加载"
              />
            )}
            登录
          </button>
        </Form>
      </div>
      <div className={style.blackBottom}></div>
    </div>
  );
};
